















































import Vue from "vue"
import { mapGetters, mapState } from "vuex"

import { RtbButton } from "@/components/ui"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import MobileDrawingPicker from "./MobileDrawingPicker.vue"
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"

export default Vue.extend({
  name: "MobileDrawing",
  components: {
    RtbButton,
    ResizableText,
    MobileDrawingPicker,
    DrawingTextInput: () =>
      import("@/components/GroupTeams/Common/Games/DrawingTextInput.vue"),
    Drawing: () => import("@/components/GroupTeams/Common/Games/Drawing.vue")
  },
  props: {
    mode: {
      type: [String, null],
      required: true
    },
    mission: {
      type: Object,
      required: true
    },
    isLandScape: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      imageSubmitted: false
    }
  },
  computed: {
    ...mapGetters(["isScribe", "missionCompleted"]),
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapState("drawing", ["assignedTeamId", "assignedUserId"]),
    isMissionCompleted() {
      return this.missionCompleted && !this.isPictionary
    },
    isPictionaryTeam() {
      return this.assignedTeamId === this.user.teamID
    },
    isPictionaryUser() {
      return this.assignedUserId === this.user.id
    },
    isPictionary() {
      return this.mission.behavior === MissionType.DrawingPictionary
    },
    hasPallette() {
      return (
        this.mode === Mode.Play &&
        (!this.isPictionary ||
          this.isPictionaryTeam ||
          this.isPictionaryUser ||
          this.isHost)
      )
    },
    message() {
      if (this.isPictionary && !this.assignedTeamId && !this.assignedUserId) {
        return "Waiting on assignment..."
      }

      if (this.isMissionCompleted) {
        return "Done"
      } else {
        return "Please turn phone to landscape mode to paint"
      }
    }
  }
})
