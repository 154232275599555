var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-drawing" },
    [
      (_vm.isLandScape && !_vm.isMissionCompleted) ||
      (_vm.isPictionary && !_vm.isPictionaryTeam && !_vm.isPictionaryUser)
        ? [
            _c(
              "div",
              { staticClass: "mobile-drawing__side" },
              [
                _vm.isPictionary ||
                (_vm.imageSubmitted && _vm.isScribe) ||
                _vm.isMissionCompleted
                  ? _c("DrawingTextInput", {
                      staticClass: "mobile-drawing__input",
                      attrs: { mode: _vm.mode, mission: _vm.mission },
                    })
                  : _vm._e(),
                !_vm.isPictionary &&
                _vm.isScribe &&
                !_vm.imageSubmitted &&
                !_vm.isMissionCompleted
                  ? [
                      _c("ResizableText", {
                        attrs: { message: _vm.mission.instructions },
                      }),
                      _c(
                        "RtbButton",
                        {
                          on: {
                            click: function ($event) {
                              _vm.imageSubmitted = true
                            },
                          },
                        },
                        [_c("strong", [_vm._v("Submit ")])]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("Drawing", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.hasPallette &&
                    !_vm.isMissionCompleted &&
                    !_vm.imageSubmitted,
                  expression:
                    "hasPallette && !isMissionCompleted && !imageSubmitted",
                },
              ],
              key: "drawing-component-" + _vm.mission.id,
              staticClass: "mobile-drawing__canvas",
              attrs: {
                mission: _vm.mission,
                height: 270,
                width: 510,
                bg: "#fff",
              },
            }),
            _vm.hasPallette ? _c("MobileDrawingPicker") : _vm._e(),
          ]
        : _c("ResizableText", {
            staticClass: "mobile-drawing__info",
            attrs: { message: _vm.message },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }